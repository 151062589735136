import classNames from 'classnames';
import React, { useContext } from 'react';
import css from './ToggleSelector.module.scss';

interface ContextState {
   value: any
   onChange: (value: any) => void
}

const Context = React.createContext<ContextState | null>(null)

interface Props<T> {
   className?: string
   value: T
   onChange: (value: T) => void
}

export default function ToggleSelector<T>({ className, value, onChange, children }: React.PropsWithChildren<Props<T>>) {
   return <div className={classNames(css.toggleSelector, className)} role='radiogroup'>
      {/* button to get all the clicks when wrapped in <label> */}
      <button aria-hidden />
      <div className={css.wrapper}>
         <Context.Provider value={{ value, onChange }}>
            {children}
         </Context.Provider>
      </div>
   </div>
}

ToggleSelector.Button = Button

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
   value: any
   disabled?: boolean
}

function Button({ className, onClick, value, disabled, ...props }: ButtonProps) {
   const context = useContext(Context)
   if (!context) {
      throw new Error('Cannot use ToggleSelector.Button outside of ToggleSelector')
   }

   function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
      context!.onChange(value)
      onClick?.(e)
   }

   return <UncontrolledToggleButton className={className} active={context.value === value} onClick={handleClick} disabled={disabled} type='button' {...props} />
}

interface UncontrolledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   active: boolean
}

export function UncontrolledToggleButton({ className, active, ...props }: UncontrolledButtonProps) {
   return <button className={classNames(css.button, active && css.active, className)} role='radio' aria-checked={active} {...props} />
}
