import React from 'react';
import { CarParkIcon, HotDeskIcon, LockerIcon, RoomIcon } from './icons';
import { ResourceType } from '../model';

 interface ResourceTypeIconProps extends React.SVGProps<SVGSVGElement> {
    type: ResourceType
    title?: string
 }
 
 export function ResourceTypeIcon({ type, ...props }: React.PropsWithChildren<ResourceTypeIconProps>) {
    return React.createElement(getRoomTypeIcon(type), props)
 }
 
 export function getRoomTypeIcon(type: ResourceType) {
    switch (type) {
       case 'MeetingRoom': return RoomIcon
       case 'HotDesk': return HotDeskIcon
       case 'Locker': return LockerIcon
       case 'ParkingSpace': return CarParkIcon
    }
 
    //const _exhaustive: never = type
 }
