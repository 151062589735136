export const AllResourceTypes = ['MeetingRoom', 'HotDesk', 'Locker', 'ParkingSpace'] as const
export type ResourceType = typeof AllResourceTypes[number]

export interface Person {
   name: string
   emailAddress: string
   organisation: string | null
   jobTitle: string | null
   department: string | null
   isGuest: boolean
}

export interface Resource {
    name: string
    emailAddress: string
    organisation: string | null
    roomType: ResourceType
    informationText?: string
    seats: number
    attributes: string[]
    location: string
    hasFloorPlan: boolean
    floor?: string
 }

 
export function getResourceTypeName(type: ResourceType, quantity = 1) {
    switch (type) {
       case 'MeetingRoom': return quantity === 1 ? 'Room' : 'Rooms'
       case 'HotDesk': return quantity === 1 ? 'Hot Desk' : 'Hot Desks'
       case 'Locker': return quantity === 1 ? 'Locker' : 'Lockers'
       case 'ParkingSpace': return quantity === 1 ? 'Carpark' : 'Carparks'
    }
 }

 export interface SearchFilter {
   resourceType: ResourceType
   locations: string[]
   attributes: string[]
   sizeIndex: number
}

type RecurrenceRange = {
   startDate: string
} & ({
   type: 'endDate'
   endDate: string
} | {
   type: 'numbered'
   numberOfOccurrences: number
} | {
   type: 'noEnd'
})

export type RecurrenceDayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
export const AllRecurrenceDaysOfWeek: readonly RecurrenceDayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

type RecurrencePattern = {
   type: 'daily'
   interval: number
} | {
   type: 'weekly'
   interval: number
   daysOfWeek: RecurrenceDayOfWeek[]
   firstDayOfWeek?: RecurrenceDayOfWeek
} | {
   type: 'absoluteMonthly'
   interval: number
   dayOfMonth: number
} | {
   type: 'relativeMonthly'
   interval: number
   daysOfWeek: RecurrenceDayOfWeek[]
} | {
   type: 'absoluteYearly'
   interval: number
   dayOfMonth: number
   month: number
} | {
   type: 'relativeYearly'
   interval: number
   daysOfWeek: RecurrenceDayOfWeek[]
   month: number
}

export type Recurrence = {
   pattern: RecurrencePattern
   range: RecurrenceRange
}

 
