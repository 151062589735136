import moment from "moment";
import { Person, Recurrence, Resource } from "../model";

declare const ApiConfig:  {baseUrl: string}

const baseUrl = ApiConfig.baseUrl

export interface RoomAttribute {
   id: string
   name: string
   url: string
}

export interface PluginLoginData {
   myself: Person
   roomSizesFilter: number[],
   resources: Resource[]
   attributes: RoomAttribute[]
   organisationId: string
}

export async function login(token: string): Promise<PluginLoginData | null> {
   try {
      const res = await post<PluginLoginData>('api/login/outlook_sso', {token})
      return res

   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return null
      }
      throw e;
   }
}


export async function whoAmI() {
   try {
      return await get<PluginLoginData>('api/outlookaddin/whoami');
   } catch (e) {
      if (e instanceof HttpError && e.status === 401) {
         return null
      }
      throw e;
   }
}

export interface AvailabilitySearchItem {
   before: number
   after: number
   emails: string[]
   pinned: string[]
   count: number
}

export type Suggestion = {
   start: number
   participantsAvailable: boolean
   selected: string[][]
   available: string[][]
   incomplete: boolean[]
   resourcesAvailable: boolean
}

export async function availabilitySearch(
   organiser: string, pool: AvailabilitySearchItem[],
   participants: string[], start: number, duration: number, meetingId: string | undefined, allDay: boolean, recurrence: Recurrence | null, numberOfSuggestions: number): Promise<Suggestion[]> {

   const now =  Date.now()
   const earliestDateTime = Math.max(moment(start).startOf('d').valueOf(), now)

   const res = await post<AvailabilityResult[]>('api/calendar/availabilitySearch', {
      organiserEmail: organiser,
      anyAvailable: pool.map(p => ({
         mailboxes: p.emails,
         pinned: p.pinned,
         requiredCount: p.count,
         preBookMinutes: -p.before,
         postBookMinutes: p.after,
      })),
      allAvailable: participants,
      earliestDateTime: moment(earliestDateTime).format(),
      preferredDateTime: moment(start).format(),
      latestDateTime: moment(earliestDateTime).endOf('d').format(),
      honourWorkingHours: false,
      duration: allDay ? duration / 24 : duration,
      durationUnit: allDay ? 2 : 0, // 0 = minutes, 2 = days
      meetingId,
      recurrence,
      allDay,
      numberOfSuggestions,
   })

   return res.map(x => ({
      start: new Date(x.startDateTime).valueOf(),
      participantsAvailable: x.allAvailable,
      resourcesAvailable: !x.incomplete,
      selected: x.anyAvailable.map(x => [...x.pinned, ...x.selected]),
      available: x.anyAvailable.map(x => x.available),
      incomplete: x.anyAvailable.map(x => x.incomplete),
   })).sort((a, b) => a.start - b.start)
}

interface AvailabilityResult {
   anyAvailable: Array<{
      available: string[]
      selected: string[]
      pinned: string[]
      incomplete: boolean
   }>
   incomplete: boolean
   allAvailable: boolean
   startDateTime: string
   endDateTime: string
}

async function get<T>(url: string) {
   return await fetchJson<T>(url, {});
}

async function post<T>(url: string, body: any, headers: Record<string, string> = {}) {
   return await fetchJson<T>(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...headers },
      body: JSON.stringify(body)
   });
}

async function fetchJson<T>(path: string, { headers, ...init }: RequestInit) {
   const response = await fetch(`${baseUrl}/${path}`, {
      credentials: 'include',
      ...init,
      headers: {
         ...headers,
         'Accept': 'application/json',
      }
   });
   if (response.status === 204) {
      return undefined! as T;
   }
   if (response.status >= 400) {
      let body = await response.text()
      let error: ApiError | null = null

      try {
         const json = await JSON.parse(body)
         if ('title' in json) {
            error = json
         }
      } catch (e) {
         ;
      }

      if (!error) {
         error = { title: body, detail: response.statusText, code: 'ErrorGeneral', instance: path, status: response.status }
      }
      throw new HttpError(response.status, response.statusText, error);
   }
   return await response.json() as T;
}

export interface ApiError {
   status: number
   instance: string
   code: ErrorCode

   title: string
   detail: string
}

type ErrorCode = 'ErrorGeneral' | 'ErrorTimeConflict' | 'ErrorMeetingNotFound' | 'ErrorMeetingLinkNotFound'

export class HttpError extends Error {
   public readonly status: number;
   public readonly statusText: string;
   public readonly apiError: ApiError;

   constructor(status: number, statusText: string, apiError: ApiError) {
      super(`${status} ${statusText}`);

      this.status = status;
      this.statusText = statusText;
      this.apiError = apiError;

      Object.setPrototypeOf(this, HttpError.prototype);
   }
}




