import classNames from "classnames";
import * as React from "react";
import Logo from './bookit.png'
import './Header.scss'

export default function Header({className}: {className?: string}) {
  const title = "TeSA Room Search"
  return (
    <section className={classNames("header", className)}>
      <img src={Logo} alt={title} title={title} />
      <h1 className="title">{title}</h1>
    </section>
  );
}

