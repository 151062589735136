import { Dropdown, IDropdownOption, ISelectableOption, ResponsiveMode, Spinner } from "@fluentui/react"
import { useEffect, useMemo } from "react"
import { getResourceTypeName, ResourceType, SearchFilter } from "../model"
import { ResourceTypeIcon } from "./ResourceIcon"
import ToggleSelector from "./ToggleSelector"
import css from './SearchPanel.module.scss'
import FormSection from "./FormSection"
import { useAppState } from ".."
import { RoomAttribute } from "../utils/api"
import { useSettings } from "../utils/settings"

interface SearchPanelProps {
   setFilter: (value: React.SetStateAction<SearchFilter>) => void
   filter: SearchFilter
}

export default function SearchPanel({ filter, setFilter }: SearchPanelProps) {

   const { isLoading, roomSizes, resourceTypes, locations: allLocations, attributes: allAttriubtes } = useSearchPanel()

   const {setLocations} = useSettings(settings => settings.locations && setFilter(({ ...filter, locations: settings.locations})))

   useEffect(() => {
      if (filter.locations.length === 0 && allLocations && allLocations.length > 0) {
         setFilter(f => ({ ...f, locations: [allLocations[0]]}))
      }
   }, [allLocations, filter.locations.length, setFilter])

   const locationItems = useMemo(() => {
      if (allLocations === null) return []
      return allLocations.sort().map(l => ({ key: l, text: l } as IDropdownOption))
   }, [allLocations])

   const attributeItems = useMemo(() => {
      if (allAttriubtes === null) return []
      return allAttriubtes.map(a => ({ key: a.id, text: a.name, data: a } as IDropdownOption<RoomAttribute>))
   }, [allAttriubtes])

   const handleLocationChange = (key: string, active: boolean) => {
      if (allLocations === null) return
      const newLocations = (allLocations.filter(l => (filter.locations.some(loc => loc === l) && (key !== l || active))
         || (key === l && active)))
         .map(o => o as string)
      setLocations(newLocations)
      setFilter(f => ({ ...f, locations: newLocations }))
   }

   const handleAttributeChange = (key: string, active: boolean) => {
      if (allAttriubtes === null) return
      const newAttributes = (allAttriubtes.filter(a => (filter.attributes.some(att => att === a.id) && (key !== a.id || active))
         || (key === a.id && active)))
         .map(o => o.id)
      setFilter(f => ({ ...f, attributes: newAttributes }))
   }

   if (isLoading) {
      return <Spinner label='Loading...' />
   }

   return <div>
      {allLocations && allLocations.length > 1 && <Dropdown
         label="Building"
         options={locationItems}
         multiSelect
         selectedKeys={filter.locations}
         responsiveMode={ResponsiveMode.large}
         onChange={(e, o) => {
            if (o) {
               handleLocationChange(o.key as string, Boolean(o.selected))
            }
         }}
      />}

      {resourceTypes && resourceTypes.length > 1 && <FormSection label='Resource Type'>
         <ToggleSelector value={filter.resourceType} onChange={type => setFilter(f => ({ ...f, resourceType: type }))}>
            {resourceTypes && resourceTypes.map(type => <ToggleSelector.Button key={type} value={type} className={css.toggleWithIcon}>
               <ResourceTypeIcon type={type} />
               {getResourceTypeName(type)}
            </ToggleSelector.Button>)}
         </ToggleSelector>
      </FormSection>}

      {filter.resourceType === 'MeetingRoom' && roomSizes && <FormSection label='Size'>
         <ToggleSelector value={filter.sizeIndex} onChange={sizeIndex => setFilter(f => ({ ...f, sizeIndex }))}>
            <ToggleSelector.Button className={css.size} value={-1}>Any</ToggleSelector.Button>
            {roomSizes.map(x => <ToggleSelector.Button key={x.index} className={css.size} value={x.index}>{x.name}</ToggleSelector.Button>)}
         </ToggleSelector>
      </FormSection>}

      {filter.resourceType === 'MeetingRoom' && allAttriubtes && allAttriubtes.length > 1 && <Dropdown
         label="Attributes"
         defaultSelectedKeys={filter.attributes}
         options={attributeItems}
         onRenderOption={renderOption}
         multiSelect
         onChange={(e, o) => {
            if (o) {
               handleAttributeChange(o.key as string, Boolean(o.selected))
            }
         }}
      />}

   </div>
}

function renderOption(option?: ISelectableOption<RoomAttribute>): JSX.Element {
   return (
      <div className={css.attribute}>
         {option && option.data && <img className={css.attributeImage} src={option.data.url} alt={option.text} />}
         {option && <span>{option.text}</span>}
      </div>
   );
};

export function useSearchPanel() {

   const {user, loginData} = useAppState()

   const roomSizes = useMemo(() => {
      if (loginData === null) return null
      return getSizeFilters(loginData.roomSizesFilter).map((name, index) => ({ name, index }))
   }, [loginData])

   const locations = useMemo(() =>
      loginData === null ? null : loginData.resources.map(r => r.location).filter((v, i, a) => a.indexOf(v) === i),
      [loginData])

   const resourceTypes = useMemo(() =>
      loginData === null ? null : loginData.resources.map(r => r.roomType).filter((v, i, a) => a.indexOf(v) === i).sort(resourceOrder),
      [loginData])

   return {
      isLoading: loginData === null,
      roomSizes,
      attributes: loginData?.attributes ?? null,
      locations,
      resourceTypes,
      resources: loginData?.resources ?? null,
      user
   }
}

function resourceOrder(a: ResourceType, b: ResourceType): number {
   if (b === 'MeetingRoom' && a !== 'MeetingRoom') return 1;
   if (b !== 'MeetingRoom' && a === 'MeetingRoom') return -1;

   return a.localeCompare(b)
}

function getSizeFilters(roomSizesFilter: readonly number[]) {
   return Array.from(sizeFiltersGenerator(roomSizesFilter))
}

function* sizeFiltersGenerator(roomSizesFilter: readonly number[]) {
   yield `< ${roomSizesFilter[0]}`

   for (let i = 1; i < roomSizesFilter.length; i++) {
      const a = roomSizesFilter[i - 1]
      const b = roomSizesFilter[i] - 1

      yield a === b ? `${a}` : `${a} – ${b}`
   }

   yield `${roomSizesFilter[roomSizesFilter.length - 1]}+`
}


