
export type ItemType = NonNullable<typeof Office.context.mailbox.item>


export function getEmailAddress(item: ItemType) {
   return Office.context.mailbox.userProfile.emailAddress
}

export async function addAppointmentTimeChanged(item: ItemType, handler: (args: Office.AppointmentTimeChangedEventArgs) => void) {
   if (!isSetSupported('1.7')) {
      console.warn('AppointmentTimeChanged not supported')
   }
   await toPromise(cb => item.addHandlerAsync(Office.EventType.AppointmentTimeChanged, handler, cb))
}

export async function removeAppointmentTimeChanged(item: ItemType) {
   if (!isSetSupported('1.7')) {
      console.warn('AppointmentTimeChanged not supported')
   }
   await toPromise(cb => item.removeHandlerAsync(Office.EventType.AppointmentTimeChanged, cb))
}


export async function addResourceIfNotPresent(item: ItemType, recipient: Office.EmailUser): Promise<void> {
   let attendees = await getRequiredAttendees(item)
   if (attendees.some(att => isSameEmailAddress(att.emailAddress, recipient.emailAddress))) return
   let locations = await getLocationDetails(item)
   if (locations !== null && locations.some(loc => isLocationRoom(loc) && isSameEmailAddress(loc.emailAddress, recipient.emailAddress))) return
   await addRoom(item, recipient)
}

export function getRequiredAttendees(item: ItemType): Promise<Office.EmailAddressDetails[]> {
   return toPromise((cb) => item.requiredAttendees.getAsync(cb))
}

export function getLocationDetails(item: ItemType): Promise<Office.LocationDetails[] | null> {
   if (!isSetSupported('1.8')) {
      return Promise.resolve([])
   }
   return toPromise((cb) => item.enhancedLocation ? item.enhancedLocation.getAsync(cb) : [])
}
export function getStart(item: ItemType): Promise<Date> {
   return toPromise((cb) => item.start.getAsync(cb))
}

export function getEnd(item: ItemType): Promise<Date> {
   return toPromise((cb) => item.end.getAsync(cb))
}

export function addRoom(item: ItemType, recipient: Office.EmailUser | Office.EmailAddressDetails): Promise<void> {

   if (isSetSupported('1.8') && item.enhancedLocation) {
      const locationId = {
         id: recipient.emailAddress,
         type: Office.MailboxEnums.LocationType.Room
      }
      return toPromise((cb) => item.enhancedLocation.addAsync([locationId], cb))
   }

   return toPromise((cb) => item.requiredAttendees.addAsync([recipient], cb))
}

export function isSetSupported(version: string) {
   return Office.context.requirements.isSetSupported('Mailbox', version)
}

export function isSameEmailAddress(email1: string, email2: string) {
   return email1.localeCompare(email2, undefined, { sensitivity: 'accent' }) === 0
}


export type AsyncCallback<TResult> = (asyncResult: Office.AsyncResult<TResult>) => void
export function toPromise<T>(work: (callback: AsyncCallback<T>) => void): Promise<T> {
   return new Promise((resolve, reject) => {
      work((asyncResult: Office.AsyncResult<T>) => {
         if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            reject(asyncResult.error);
         } else {
            resolve(asyncResult.value)
         }
      })
   });
}


function isLocationRoom(location: Office.LocationDetails) {
   return location.locationIdentifier.type === Office.MailboxEnums.LocationType.Room
}

export interface OfficeError {
   code: number
   message: string
   name: string
}


export function instanceOfOfficeError(object: any): object is OfficeError {
   return (typeof object === 'object') && 'code' in object && 'message' in object && 'name' in object
}
