import App from "./components/App";
import { initializeIcons } from '@fluentui/react/lib/Icons'
import { ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import 'promise-polyfill/src/polyfill'
import 'react-app-polyfill/ie11';
import { useContext, useEffect, useState } from "react";
import * as Api from "./utils/api";
import { instanceOfOfficeError, OfficeError } from "./utils/office";

initializeIcons();

let isOfficeInitialized = false;

const render = () => {
   ReactDOM.render(
      <ThemeProvider>
         <AppStateProvider>
            <App isOfficeInitialized={isOfficeInitialized} />
         </AppStateProvider>
      </ThemeProvider>
      , document.getElementById("root")
   );
};

/* Render application after Office initializes */
Office.initialize = () => {
   isOfficeInitialized = true;
   render();
};

interface AppStateType {
   user: string
   loginData: Api.PluginLoginData | null
   loginError: OfficeError | null
}

const AppContext = React.createContext<AppStateType | null>(null)

function AppStateProvider({ children }: React.PropsWithChildren<{}>) {
   const user = Office.context.mailbox.userProfile.emailAddress
   const [loginData, setLoginData] = useState<Api.PluginLoginData | null>(null)
   const [loginError, setLoginError] = useState<OfficeError | null>(null)

   useEffect(() => {
      async function login(user: string) {

         if (!Office.context.requirements.isSetSupported('IdentityAPI', '1.3')) {
            setLoginError({code: -2, name: "Unsupproted Outlook version", message: "This version of Outlook cannot run this add-in"})
         }

         try {
            let data = await Api.whoAmI()

            if (data === null) {
               const options = { allowSignInPrompt: true, allowConsentPrompt: true, forMSGraphAccess: false };
               const token = await OfficeRuntime.auth.getAccessToken(options)
               console.log("getAccessToken", token)
               data = await Api.login(token)
            }
            if (data) {
               setLoginData(data)
            } else {
               setLoginError({code: -1, name: "Failed to login", message: "Unable to login to Book-It. If this problem persists please contact your administrator."})

            }
         } catch (e) {
            if (instanceOfOfficeError(e)) {
               console.error(`Failed to getAccessToken: code=${e.code}, name=${e.name}, message=${e.message}`)
               setLoginError(e)
            } else {
               console.error('Failed to getAccessToken', e)
               setLoginError({code: -1, name: "Unknown", message: "An unkown error occured"})

            }
         }
      }
      if (user !== null) {
         login(user)
      }

   }, [user])


   return <AppContext.Provider value={{ user, loginData, loginError }}>
      {children}
   </AppContext.Provider>

}

export function useAppState() {
   const data = useContext(AppContext)
   if (!data) throw new Error("Must use <AppStateProvider/> component")
   return data
}




