import { Resource, SearchFilter } from "../model"

export function filterResources(filter: SearchFilter, roomSizeFilter: number[], resources: Resource[]) {
   return resources.filter(room => {
      if (filter.resourceType !== room.roomType) return false
      if (!filter.locations.some(l => l === room.location)) return false

      if (room.roomType !== 'MeetingRoom') return true

      if (!filter.attributes.every(a => room.attributes.includes(a))) return false
      if (!sizeMatch(room, filter.sizeIndex, roomSizeFilter)) return false

      return true
   })
}

export function sizeMatch(room: Resource, sizeIndex: number, sizes: number[]): boolean {
   if (sizeIndex === -1) return true
   if (sizeIndex === 0) return room.seats < sizes[0]
   if (sizeIndex === sizes.length) return sizes[sizes.length - 1] <= room.seats
   return sizes[sizeIndex - 1] <= room.seats && room.seats < sizes[sizeIndex]
}
