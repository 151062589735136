import { Icon, Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react"
import { Resource } from "../model"
import FormSection from "./FormSection"
import { ResourceTypeIcon } from "./ResourceIcon"
import css from './SuggestionsList.module.scss'

interface SuggestionsListProps {
   onClick: (resource: Resource) => void
   suggestions: Resource[]
   loading: boolean
}


const wrapperStyles = { display: 'flex', flexFlow: 'column' };


const getCustomElements = (): JSX.Element => {
   return (
      <div style={wrapperStyles}>
         <ShimmerElementsGroup
            shimmerElements={[
               { type: ShimmerElementType.circle, height: 40 },
               { type: ShimmerElementType.gap, width: 16, height: 40 },
            ]}
         />
      </div>
   );
}

export default function SuggestionsList({ suggestions, onClick, loading }: SuggestionsListProps) {
   return <FormSection label='Suggested Resources'>
      {loading && [0, 1, 2, 3].map(i => <Shimmer className={css.shimmer} customElementsGroup={getCustomElements()} width="300" isDataLoaded={false} key={i}/>)}
      {!loading && suggestions.length === 0 && <span className={css.empty}>Nothing available</span>}
      {!loading && suggestions.length > 0 && <ul>
         {suggestions.map(res =>
            <ResourceItem resource={res} key={res.emailAddress} onClick={onClick} />)
         }
      </ul>}
   </FormSection>
}

interface ResourceItemProps {
   resource: Resource
   onClick: (resource: Resource) => void
}

function ResourceItem({ resource, onClick }: ResourceItemProps) {
   return <li className={css.resourceItem} id={resource.emailAddress} onClick={() => onClick(resource)}>
      <div className={css.icon}><ResourceTypeIcon type={resource.roomType} /></div>
      <div className={css.info}>
         <span>{resource.name}</span>
         {resource.roomType === 'MeetingRoom' && <div className={css.roomSize}>
            <Icon className={css.sizeIcon} iconName='Contact' />
            <span>{resource.seats}</span>
         </div>
         }
      </div>
   </li>
}
