import { useEffect, useRef, useState } from "react";
import { toPromise } from "./office";

export interface AddinSettings {
   locations: string[] | null
}

export function useSettings(onLoad: (a: AddinSettings) => void) {
   const onLoadRef = useRef(onLoad)

   useEffect(() => {
      onLoadRef.current = onLoad
   }, [onLoad])

   useEffect(() => {
      async function load() {
         const location = Office.context.roamingSettings.get('Location') as string[]
         onLoadRef.current({locations: location ?? null})
      }
      load()
   }, [])

   async function save() {
      await toPromise(cb => Office.context.roamingSettings.saveAsync(cb));
   }

return {
      async setLocations(l: string[]) {
         Office.context.roamingSettings.set('Location', l)
         await save()
      }
   }
}


