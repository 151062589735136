import css from './FormSection.module.scss'

interface FormSectionProps {
   label: string
}

export default function FormSection({label, children}: React.PropsWithChildren<FormSectionProps>) {
   return <div className={css.section}>
      <span className={css.label}>{label}</span>
      {children}
   </div>
}
